function isOver21(){
    Cookies.set('wordpress_over_21', 'true', { expires: 30 }); // 30 Days
    fadeAgeGate();
    showMain();
}

function isNotOver21(){
    alert('Sorry, then you are unable to visit this site.');
    return;
}

$(document).ready(function() {

    $('#ageGateNo').click(function(e){
        e.preventDefault();
        isNotOver21();
    });

    $('#ageGateYes').click(function(e){
        e.preventDefault();
        isOver21();
    });
});
